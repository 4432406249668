import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/home/Home';
import Catalog from './pages/catalog/Catalog';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import NotFound from './pages/not-found/not-found';
import PrivacyPolitics from './pages/privacy-politics/privacy-politics';
import TermsAndConditions from './pages/terms-and-conditions/terms-and-conditions';
import Contact from './pages/contact/contact';
import Login from './pages/login/login';
import SignUp from './pages/sign-up/sign-up';
import { useEffect } from 'react';
import CatalogItem2 from './pages/catalog-item-2/CatalogItem';
import ProductItem from './pages/product/Product';

function App() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])
  

  return (
    <div className="main">
      <Header/>
      <Routes>
        <Route element={<Home/>} path="/" />
        <Route element={<Catalog/>} path="/catalog" />
        <Route element={<CatalogItem2/>} path="/catalog/:item_id" />
        <Route element={<ProductItem/>} path="/products/:product_id" />
        <Route element={<PrivacyPolitics/>} path='privacy-politics' />
        <Route element={<TermsAndConditions/>} path='terms-and-conditions' />
        <Route element={<Contact/>} path='contact' />
        <Route element={<Login/>} path='login' />
        <Route element={<SignUp/>} path='sign-up' />
        <Route element={<NotFound/>} path="*" />
      </Routes>
      {window.location.pathname !== '/' && <Footer/>}
    </div>
  );
}

export default App;
