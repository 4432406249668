import { Link, useLocation } from 'react-router-dom'
import './style.sass'
import logo from '../../assets/icons/logo-black-and-white.png'
import { Links } from '../Links'
import { useEffect, useState } from 'react'

function Footer() {
  const location = useLocation()
  const [pathname, updatePathname] = useState(window.location.pathname)

  useEffect(() => {
    updatePathname(window.location.pathname)
  }, [location])  

  return (
    <div className={'footer '+ (pathname.length > '/catalog'.length ? 'blur-bg' : '')}>
      {
        pathname !== '/catalog' &&
        <>
          <div className='intro-text'>
            <h3>Nossas redes</h3>
            <h2>Nos acompanhe<br/> nas <span>redes sociais</span></h2>
            <p>Você pode acompanhar nossas oferta através dos nossos grupos.</p>
          </div>
          <Links />
        </>
      }
      <div className='group'>
        <p>Grupo:</p>
        <img src={logo} className='logo' />
      </div>
      <div className='bottom-links row'>
        <Link to='privacy-politics'>Políticas de Privacidade</Link>
        <Link to='terms-and-conditions'>Termos e condições</Link>
        <Link to='contact'>Contato</Link>
      </div>
    </div>
  )
}

export default Footer