import React, { Component } from 'react'
import './style.sass'

interface State {
    slideWidth: number,
    slideHeight: number,
    slideMargin: number,
    slides: any[],
    initialSlides: any[],
    currentSlide: any,
    sliderWidth: any,
    sliderInterval: any,
    sliderRef: any
};  

export default class AwesomeSlider extends Component<{
  slideWidth: any,
  slideHeight?: any,
  ratio?: number,
  slideMargin: any,
  applyFocus?: boolean,
  passedSlides: any[], 
  currentSlide: any }> {
    state: State = {
        slideWidth: 0,
        slideHeight: 0,
        slideMargin: 0,
        slides: [],
        initialSlides: [],
        currentSlide: 1,
        sliderWidth: 0,
        sliderInterval: null,
        sliderRef: null
    }
    sliderRef = React.createRef<any>()
    
    componentDidMount() {
        if(!this.sliderRef.current) return;

        const sliderWidth = (this.sliderRef.current as HTMLElement).offsetWidth;
        let slideWidth = this.props.slideWidth;
        let slideMargin = this.props.slideMargin;
        let slideHeight = 0;

        if(typeof this.props.slideWidth === 'string' && this.props.slideWidth.includes('%')) {
          const percentage = parseFloat(this.props.slideWidth.replace('%', ''));

          slideWidth = sliderWidth / 100 * percentage;
        };

        if(typeof this.props.slideMargin === 'string' && this.props.slideMargin.includes('%')) {
          const percentage = parseFloat(this.props.slideMargin.replace('%', ''));

          slideMargin = sliderWidth / 100 * percentage;
        };

        if(this.props.ratio) {
          slideHeight = this.props.ratio * slideWidth;
        };

        if(this.props.slideHeight) {
          slideHeight = this.props.slideHeight;
        };

        let slidesCopy = [...this.props.passedSlides].map((x, index) => ({
          image: x,
          left: 0,
          slideIndex: index+1
        }));

        slidesCopy = [...slidesCopy, ...slidesCopy].map((x, index) => ({
          ...x,
          left: ((slideWidth + slideMargin) * index) + ((sliderWidth / 2) - (slideWidth / 2)),
          transition: '0.3s',
          scale: index === 0 ? '1.2' : '1'
        }));

        const startPoint = slidesCopy[0].left;

        const reverseSlides = [...slidesCopy].reverse().map((x, index) => ({
          ...x,
          left: startPoint - ((slideWidth + slideMargin) * (index + 1))
        })).reverse();
    
        const slides = [
          ...reverseSlides,
          ...slidesCopy
        ];

        this.setState({
          slides,
          initialSlides: slides,
          currentSlide: reverseSlides.length,
          slideWidth,
          slideHeight,
          slideMargin
        });
    }

    componentDidUpdate(prevProps: Readonly<{ passedSlides: any[]; currentSlide: any; }>, prevState: Readonly<{}>, snapshot?: any): void {      
        let slides: any[] = [];
        let slidesCopy = [...this.state.slides];

        if(this.props.currentSlide !== prevProps.currentSlide) {

            slides = slides.map(x => ({ ...x, transition: 0 }));

            this.setState({ slides }, () => {
              const slidesJumpCount = this.props.currentSlide - prevProps.currentSlide;

              if(slidesJumpCount > 0) {
                const startPoint = slidesCopy[slidesCopy.length-1].left;
  
                const firstSlides = slidesCopy.splice(0, slidesJumpCount).map((x, index) => ({
                  ...x,
                  left: startPoint + ((this.state.slideWidth + this.state.slideMargin) * (index + 1))
                }));
    
                slides = [...slidesCopy, ...firstSlides];
              } else {
                const startPoint = slidesCopy[0].left;

                const lastSlides = slidesCopy.splice(slidesJumpCount).reverse().map((x, index) => ({
                  ...x,
                  left: startPoint - ((this.state.slideWidth + this.state.slideMargin) * (index + 1))
                })).reverse();
    
                slides = [...lastSlides, ...slidesCopy];
              };
  
              this.setState({ slides }, () => {
                slides = [...slides].map(x => ({ ...x, transition: '0.3s' }));
    
                this.setState({ slides }, () => {
                  slides = [...slides].map((x, index) => ({
                    ...x,
                    left: x.left - ((this.state.slideWidth + this.state.slideMargin) * slidesJumpCount),
                    scale: this.props.applyFocus && this.state.currentSlide === index ? '1.2' : '1'
                  }));
  
                  setTimeout(() => {
                    this.setState({ slides }); 
                  });
                }); 
              });
            });
        };
    }

    render() {
        return (
            <div ref={this.sliderRef} className='awesome-slider-container' style={{
              height: this.state.slideHeight + (this.props.applyFocus ? this.state.slideHeight / 100 * 20 : 0) + 'px'
            }}>
                <div className='slider' style={{
                  height: this.state.slideHeight + 'px'
                }}>
                    {
                        this.state.slides.map((slide, index) => 
                            <div key={'item_slide_'+index} className={'slide '+slide.slideIndex} style={{ 
                              width: this.state.slideWidth + 'px',
                              height: this.state.slideHeight + 'px',
                              transition: slide.transition,
                              scale: slide.scale,
                              left: slide.left}}>
                                <img className='background' src={slide.image} style={{width: '100%'}} />
                            </div>  
                        )
                    }
                </div>
            </div>
        )
    }
}
