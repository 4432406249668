import { useEffect, useState } from 'react'
import './styles.sass'

interface InputOptions { 
  name: string,
  label: string,
  placeholder?: string,
  type?: string,
  value?: any,
  readonly?: boolean,
  disabled?: boolean,
  onChange?: (value: any) => any
}

export const Input = ({ name, label, placeholder, type, value, readonly, disabled, onChange }: InputOptions) => {
  const[currentValue, setCurrentValue] = useState(value)

  const onInputChange = (e: any) => {
    onChange && onChange(e.target.value);
    setCurrentValue(e.target.value);
  }

  useEffect(() => {
    setCurrentValue(value);
  }, [value])

  return (
    <div className={'input-container '+(disabled ? 'disabled' : '')}>
      <label>{label}</label>
      {
        type === 'textarea' &&
        <textarea
        name={name}
        placeholder={placeholder}
        value={currentValue}
        disabled={disabled}
        readOnly={readonly}
        onChange={onInputChange}>
        </textarea>
      }
      {
        (!type || !['date', 'textarea'].includes(type)) &&
        <input
        name={name}
        type={type ? type : 'text'}
        placeholder={placeholder}
        value={currentValue}
        disabled={disabled}
        readOnly={readonly}
        onChange={onInputChange} />
      }
    </div>
  )
}