import { Link } from 'react-router-dom'
import { Product } from '../../infrastructure/services/catalog-item'

function ItemProducts({ title, products }: { title: string, products: {product_id: number, product: Product}[] }) {
    return (
        <div className='offer-items-2'>
            <h2 className='title'>{title}</h2>
            <div className='items'>
                {
                    products.map((item, index) =>
                    <Link to={'/products/'+item.product_id}>
                        <div key={'product_'+index} className='item'>
                            <div className="item-img">
                                <img className='img' src={item.product.image} />
                            </div>
                            <div className='info row'>
                                <div className='item-info'>
                                <h3 className="cut-text">{item.product.name}</h3>
                                </div>
                            </div>
                        </div>
                    </Link>)
                }
            </div>
        </div>
    )
}

export default ItemProducts