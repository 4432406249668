import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import './style.sass'
import './ckeditor5.css'
import { Product, getProduct } from '../../infrastructure/services/catalog-item'
import editIcon from '../../assets/icons/edit.svg'
import { getUserData } from '../../infrastructure/services/user'
import AwesomeSlider from '../catalog-item-2/awesome-slider/AwesomeSlider'

function ProductItem() {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const [product, setProduct] = useState<Product | undefined>()
  const [userType, setUserType] = useState<string | undefined>()
  const userLoggedId = localStorage.getItem('auth_token') && localStorage.getItem('user_id');

  useEffect(() => {
    getUserType();
  }, [])

  useEffect(() => {
    setProduct(undefined);
    onGetProduct();
  }, [location])

  const getUserType = async () => {
    if(userLoggedId) {
      const userData = await getUserData(userLoggedId);
      if(userData) {
        setUserType(userData.usertype);
      };
    };
  }
  
  const onGetProduct = async () => {
    if(!params.product_id) {
      navigate('/catalog');

      return;
    };

    const item = await getProduct(parseInt(params.product_id as string));

    setProduct(item);
  }

  const productImages = () => {
    let images = [product?.image];

    return images as any[];
  }

  return (
    <div className='product page'>
      {product && 
      <div className='content row'>
        <div className='item-showcase'>
          <div className='slider-and-store-log-container'>
            <AwesomeSlider
            slideWidth={'100%'}
            ratio={9/16}
            slideMargin={0}
            passedSlides={productImages()}
            currentSlide={1}/>
          </div>
        </div>
        <div className='catalog-item-info'>
          <div className='name-row row'>
            <h2>{product.name}</h2>
          </div>
          <div className='description'>
            <div className='ck-content' dangerouslySetInnerHTML={{__html: product.description}}></div>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default ProductItem