import { Brand } from "./brand"
import { Store } from "./store"
import mercadoLivreLogo2 from '../../assets/images/stores/mercado-livre-2.png'
import { environment } from "../../environments/environment"

export interface Product {
    product_id: number,
    name: string,
    description: string,
    image: string,
    brand_id: number,
    category_id: number,
    extra_images: string[],
    created_at: string,
    brand?: Brand
}

interface Link {
    text: string,
    link: string
}

export interface Item {
    catalog_item_id: number;
    name: string;
    description: string;
    price?: number;
    discount_price?: number;
    discount_percentage?: string;
    store_id: number;
    products: {product_id: number, product: Product}[];
    link: string;
    links: Link[];
    coupon_links: Link[];
    coupons: string[];
    extra_info?: string;
    warning?: string;
    image?: string;
    social_media_image?: string;
    created_at?: string;
    update_at?: string;
    deleted_at?: string;
    users_other_favorites: {catalog_item: Item}[];
    related_items: Item[];
    store?: Store
}

export interface ListResponse<T> {
  data: T[],
  count: number
}

export const catalogItemMock2: Item = {
    catalog_item_id: 1,
    image: 'https://nerdsonearth.com/wp-content/uploads/2016/09/rzp0I.jpg',
    name: 'The Legend of Zelda: Tears of the Kingdom',
    description: `<p>- Mídia: Física (disco ou cartucho no caso se for Nintendo);- Console: Compatível com Nintendo SWITCH, LITE E OLED;- Versão: Importada;- Legenda ou Texto: Inglês, Francês, Espanhol, Português;- Jogadores: 1 a 4;CONTEÚDO DA EMBALAGEM:1 (um) Jogo SUPER MARIO BROS WONDER SWITCH;IMAGENS MERAMENTE ILUSTRATIVASTodas as informações divulgadas são de responsabilidade do Fabricante/FornecedorSOBRE O JOGOEncante-se com a evolução fenomenal da diversão com o MarioA jogabilidade clássica de deslocamento lateral dos jogos do Mario virou de cabeça para baixo com a chegada das flores fenomenais. Este item revolucionário ativa momentos tão espetaculares que é preciso ver para crer... Observe canos ganharem vida, use sua força devastadora como uma gigantesca bola com espinhos e até se surpreenda com situações inesperados - os eventos fenomenais.Escolha personagens heroicos de Super Mario™ e itens potenciadoresEncare esta aventura como personagens conhecidos da série, como Mario, Luigi, Peach, Daisy, Yoshi e Toad. Até aí, nada de novo, mas… dessa vez você pode usar um item potenciador surpreendente para se transformar em um elefante! Use sua tromba para golpear inimigos!Compartilhe este fenômeno com seus amigos e outros fãs do MarioJunte-se a até três amigos localmente* em um console Nintendo Switch™ e experimentem juntos os revolucionários eventos fenomenais, colaborando para chegar no objetivo final!*Acessórios adicionais, vendidos separadamente, podem ser necessários para o modo multijogador.</p>`,
    extra_info: `<div><p>Preço já incluindo 30% de cashback</p></div>`,
    price: 100,
    warning: 'Ative o cupom de 5% na página do produto!',
    link: '',
    store_id: 1,
    store: {
      store_id: 1,
      name: 'Mercado Livre',
      image: mercadoLivreLogo2
    },
    products: [
        {
            product_id: 1,
            product: {
                product_id: 1,
                name: 'Jogo 1',
                description: 'Jogo 1',
                image: 'https://nerdsonearth.com/wp-content/uploads/2016/09/rzp0I.jpg',
                brand_id: 1,
                category_id: 1,
                extra_images: [],
                created_at: 'Jogo 1',
                brand: {
                    brand_id: 1,
                    name: '',
                    large_image: '',
                    image: ''
                }
            }
        }
    ],
    coupons: ['2323'],
    links: [
        {
            text: 'Acessar',
            link: ''
        }
    ],
    coupon_links: [
        {
            text: 'Resgatar cupom',
            link: ''
        }
    ],
    related_items: [],
    users_other_favorites: [],
    created_at: new Date().toISOString()
};

export const catalogItemMock: Item = {
    catalog_item_id: 1,
    image: 'https://nerdsonearth.com/wp-content/uploads/2016/09/rzp0I.jpg',
    name: 'The Legend of Zelda: Tears of the Kingdom',
    description: `<div class="description"><h4>Prince of Persia: The Lost Crown 20% OFF</h4><div class="ck-content"><p>SYSTEM REQUIREMENTS</p><ul><li>Requires a 64-bit processor and operating system<br>&nbsp;</li><li><strong>OS:</strong> Windows 10 (64 bit only)<br>&nbsp;</li><li><strong>Processor:</strong> Intel Core i5-4460 3.4 GHz, AMD Ryzen3 1200 3.1 GHz<br>&nbsp;</li><li><strong>Memory:</strong> 8 GB RAM<br>&nbsp;</li><li><strong>Graphics:</strong> NVIDIA GeForce GTX 950 (2GB VRAM) or AMD Radeon RX 5500 XT (4GB VRAM)<br>&nbsp;</li><li><strong>DirectX:</strong> Version 11<br>&nbsp;</li><li><strong>Storage:</strong> 30 GB available space</li><li>Requires a 64-bit processor and operating system<br>&nbsp;</li><li><strong>OS:</strong> Windows 10 (64 bit only)<br>&nbsp;</li><li><strong>Processor:</strong> Intel Core i7-6700 3.4 GHz, AMD Ryzen5 1600 3.2 GHz<br>&nbsp;</li><li><strong>Memory:</strong> 8 GB RAM<br>&nbsp;</li><li><strong>Graphics:</strong> NVIDIA GeForce GTX 960 (4GB VRAM) or AMD Radeon RX 5500 XT (4GB VRAM)<br>&nbsp;</li><li><strong>DirectX:</strong> Version 11<br>&nbsp;</li><li><strong>Storage:</strong> 30 GB available space</li></ul></div></div>`,
    extra_info: `<div><p>Preço já incluindo 30% de cashback</p></div>`,
    price: 100,
    warning: 'Ative o cupom de 5% na página do produto!',
    link: '',
    store_id: 1,
    store: {
      store_id: 1,
      name: 'Mercado Livre',
      image: mercadoLivreLogo2
    },
    products: [
        {
            product_id: 1,
            product: {
                product_id: 1,
                name: 'Jogo 1',
                description: 'Jogo 1',
                image: 'https://nerdsonearth.com/wp-content/uploads/2016/09/rzp0I.jpg',
                brand_id: 1,
                category_id: 1,
                extra_images: [],
                created_at: 'Jogo 1',
                brand: {
                    brand_id: 1,
                    name: '',
                    large_image: '',
                    image: ''
                }
            }
        }
    ],
    coupons: ['2323'],
    links: [
        {
            text: 'Acessar',
            link: ''
        }
    ],
    coupon_links: [
        {
            text: 'Resgatar cupom',
            link: ''
        }
    ],
    related_items: [],
    users_other_favorites: [],
    created_at: new Date().toISOString()
};

export const getCatalog = async (params?: any) => {
    let response:any = await fetch(environment.apiUrl+'/api/catalog/items'+params, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    });

    response = await response.json();

    return response as ListResponse<Item>;
}

export const getCatalogItem = async (catalog_item_id: number) => {
  let response:any = await fetch(environment.apiUrl+'/api/catalog/items/'+catalog_item_id+'?show_extra_info=true', {
      method: 'GET',
      headers: {
          'Content-type': 'application/json'
      },
  });

  response = await response.json();

  return response as Item;
}

export const getProduct = async (product_id: number) => {
    let response:any = await fetch(environment.apiUrl+'/api/products/'+product_id, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    });
  
    response = await response.json();
  
    return response as Product;
}  

export const accessCatalogItem = async (catalog_item_id: number) => {
  await fetch(environment.apiUrl+'/api/catalog/items/'+catalog_item_id+'/access', {
      method: 'GET',
      headers: {
          'Content-type': 'application/json'
      },
  });
}

export const favoriteCatalogItem = async (catalog_item_id: number) => {
  await fetch(environment.apiUrl+'/api/catalog/items/'+catalog_item_id+'/favorite', {
      method: 'POST',
      headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('auth_token')
      },
  });
}

export const unfavoriteCatalogItem = async (catalog_item_id: number) => {
  await fetch(environment.apiUrl+'/api/catalog/items/'+catalog_item_id+'/favorite', {
      method: 'DELETE',
      headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('auth_token')
      },
  });
}

export const getFavoriteCatalogItem = async (catalog_item_id: number) => {
  let response:any = await fetch(environment.apiUrl+'/api/catalog/items/'+catalog_item_id+'/favorite', {
      method: 'GET',
      headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('auth_token')
      },
  });

  response = await response.json();

  return response as any;
}