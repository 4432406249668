import { Component } from 'react'
import { ErrorMessage, emptyField, errorsLength, formatErrors, validateEmail, validatePasswordConfirmation } from '../../infrastructure/utils/form-validators'
import { Link } from 'react-router-dom'
import { RouterProps, withRouter } from '../../infrastructure/utils/with-router-component'
import { Input } from '../../components/input'
import { SubmitBtn } from '../../components/submit'
import { encrypt } from '../../infrastructure/utils/encrypt'
import { LoginResponse, onLogin, onSignIn } from '../../infrastructure/services/user'
import { parseJwt } from '../../infrastructure/utils/JWTParser'
import './style.sass'


class Login extends Component<RouterProps> {
    state: any = {
        form: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            password_confirmation: ''
        },
        formErrors: {},
        loading: false,
        sended: false
    }

    componentDidMount(): void {
        console.log(this.props);
    }

    onSubmit = async () => {
        const errors = formatErrors({
          first_name: [emptyField(this.state.form.first_name, 'string')],
          last_name: [emptyField(this.state.form.last_name, 'string')],
          email: [validateEmail(this.state.form.email)],
          phone: [emptyField(this.state.form.phone, 'string')],
          password: [emptyField(this.state.form.password, 'string')],
          password_confirmation: [validatePasswordConfirmation(this.state.form.password, this.state.form.password_confirmation)]
        });

        this.setState({ formErrors: errors });

        if(errorsLength(errors) > 0) return;

        this.setState({ loading: true });

        try {
            const form_data = {
                ...this.state.form,
                password: encrypt(this.state.form.password),
                password_confirmation: encrypt(this.state.form.password_confirmation as string)
            }
        
            await onSignIn(form_data);
            const response = await onLogin(form_data) as LoginResponse;
    
            const result = parseJwt(response.auth_token);
            localStorage.setItem('auth_token', response.auth_token);
            localStorage.setItem('user_id', result.user.user_id);

            setTimeout(() => {
                this.setState({ sended: true, loading: false });
                this.props.navigate('/');
            });
        } catch (error) {
            this.setState({ loading: false });
        };
    }


  render() {
    return (
        <div className='page sign-up'>
            <div className='content'>
                <div className='block'>
                    <div className='text'>
                        <h1>Bem vindo!</h1>
                    </div>
                    <div className='form'>
                        <Input
                        name='first_name'
                        label='Nome'
                        placeholder='Insira seu nome'
                        onChange={e => this.setState({ form: { ...this.state.form, first_name: e } })}/>
                        <ErrorMessage errors={this.state.formErrors['first_name']} />
                        <Input
                        name='last_name'
                        label='Sobrenome'
                        placeholder='Insira seu sobrenome'
                        onChange={e => this.setState({ form: { ...this.state.form, last_name: e } })}/>
                        <ErrorMessage errors={this.state.formErrors['last_name']} />
                        <Input
                        name='email'
                        label='Email'
                        placeholder='Insira seu email'
                        onChange={e => this.setState({ form: { ...this.state.form, email: e } })}/>
                        <ErrorMessage errors={this.state.formErrors['email']} />
                        <Input
                        name='phone'
                        label='Telefone'
                        placeholder='Insira seu telefone'
                        onChange={e => this.setState({ form: { ...this.state.form, phone: e } })}/>
                        <ErrorMessage errors={this.state.formErrors['phone']} />
                        <Input
                        name='password'
                        label='Senha'
                        type='password'
                        placeholder='Insira sua senha'
                        onChange={e => this.setState({ form: { ...this.state.form, password: e } })}/>
                        <ErrorMessage errors={this.state.formErrors['password']} />
                        <Input
                        name='password_confirmation'
                        label='Confirmação de Senha'
                        type='password'
                        placeholder='Confirme sua senha'
                        onChange={e => this.setState({ form: { ...this.state.form, password_confirmation: e } })}/>
                        <ErrorMessage errors={this.state.formErrors['password_confirmation']} />
                    </div>
                    <SubmitBtn className='secondary' text='Confirmar' loadingText='Enviando...' loading={this.state.loading} onSubmit={this.onSubmit} />
                    <div className='bottom-text'>
                        <p>Já possui uma conta?</p>
                        <Link to='/login'><b>Entrar</b></Link>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default withRouter(Login);