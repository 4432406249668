import logo from '../assets/icons/logo-black-and-white.png'
import JBIndicaLogo from '../assets/icons/JBIndicaLogo.png'
import linkIcon from '../assets/icons/link-arrow.png'
import facebookIcon from '../assets/icons/facebook.png'
import telegramIcon from '../assets/icons/telegram.png'
import whatsappIcon from '../assets/icons/whatsapp.png'
import xIcon from '../assets/icons/x.png'
import { Link } from 'react-router-dom'

export const Links = () => {
    const jogoBaratoLinks = [
      {
        icon: whatsappIcon,
        name: 'Jogo Barato',
        description: 'Grupo no Whatsapp',
        link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
      },
      {
        icon: telegramIcon,
        name: 'Jogo Barato',
        description: 'Grupo no Telegram',
        link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
      },
      {
        icon: xIcon,
        name: 'Twitter / X',
        description: '',
        link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
      },
      {
        icon: facebookIcon,
        name: 'Facebook',
        description: '',
        link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
      }
    ]
    
    const JBIndicaLinks = [
      {
        icon: whatsappIcon,
        name: 'Jogo Barato',
        description: 'Grupo no Whatsapp',
        link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
      },
      {
        icon: telegramIcon,
        name: 'Jogo Barato',
        description: 'Grupo no Telegram',
        link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
      },
      {
        icon: xIcon,
        name: 'Twitter / X',
        description: '',
        link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
      },
      {
        icon: facebookIcon,
        name: 'Facebook',
        description: '',
        link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
      }
    ]

    return (
        <div className='link-blocks-containers row'>
            <div className='link-blocks-container'>
            <img src={logo} className='logo' />
            <div className='link-blocks'>
                {
                jogoBaratoLinks.map((x, i) => 
                    <Link key={'jogo_barato_link_'+i} to={x.link} target='_blank'>
                    {x.description ?
                        <div className='link-block'>
                        <div className='row'>
                            <img src={x.icon} className='icon' />
                            <img src={linkIcon} className='arrow' />
                        </div>
                        <div>
                            <h4>{x.name}</h4>
                            {x.description && <p>{x.description}</p>}
                        </div>
                        </div> :
                        <div className='link-block no-description'>
                        <div className='row'>
                            <img src={x.icon} className='icon' />
                            <h4>{x.name}</h4>
                            <img src={linkIcon} className='arrow' />
                        </div>
                        </div>}
                    </Link>
                )
                }
            </div>
            </div>
            <div className='link-blocks-container'>
            <img src={JBIndicaLogo} className='logo' />
            <div className='link-blocks'>
                {
                JBIndicaLinks.map((x, i) => 
                <Link key={'jb_indica_link_'+i} to={x.link} target='_blank'>
                    {x.description ?
                        <div className='link-block'>
                        <div className='row'>
                            <img src={x.icon} className='icon' />
                            <img src={linkIcon} className='arrow' />
                        </div>
                        <div>
                            <h4>{x.name}</h4>
                            {x.description && <p>{x.description}</p>}
                        </div>
                        </div> :
                        <div className='link-block no-description'>
                        <div className='row'>
                            <img src={x.icon} className='icon' />
                            <h4>{x.name}</h4>
                            <img src={linkIcon} className='arrow' />
                        </div>
                        </div>}
                </Link>
                )
                }
            </div>
            </div>
        </div>
    )
}
