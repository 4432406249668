import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './style.sass'

import logo from '../../assets/images/logo.png'
import profileIcon from '../../assets/icons/profile.png'
import searchIcon from '../../assets/icons/search.png'
import { Category, getCategories } from '../../infrastructure/services/category'
import { User, getUserData } from '../../infrastructure/services/user'

function Header() {
  const location = useLocation()
  const navigate = useNavigate()
  const [showCategories, toggleCategories] = useState(false)
  const [showStores, toggleStores] = useState(false)
  const [search, updateSearch] = useState('')
  const [categories, setCategories] = useState<Category[]>([])
  const [userData, setUserData] = useState<User>()
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const [openedMenu, setOpenedMenu] = useState<boolean>(false)

  useEffect(() => {
    document.addEventListener('click', e => {
      const target = e.target as HTMLElement;

      const menuItems = Array.from(document.querySelectorAll('.menu-item'));

      if(menuItems.filter(x => x.contains(target)).length === 0) {
        showCategories && toggleCategories(false);
        showStores && toggleStores(false);
      };
    });
  }, [showCategories, showStores])

  useEffect(() => {
    onGetCategories();

    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
      setOpenedMenu(window.innerWidth > 1000);
    });

    document.addEventListener('click', e => {
      const target = e.target as HTMLElement;
      const menuBtn = document.querySelector('.menu-btn') as HTMLElement;
      const searchInput = document.querySelector('.search-input') as HTMLElement;

      if(searchInput.contains(target) && target.className !== 'search-icon') return;

      if(menuBtn && menuBtn.contains(target)) {
        setOpenedMenu(!menuBtn.classList.contains('opened'));
      } else {
        setOpenedMenu(false);
      };
    });
  }, [])

  useEffect(() => {
    onGetUserData();
  }, [location])
  

  const onGetUserData = async () => {
    const user_id = localStorage.getItem('user_id');

    if(user_id && localStorage.getItem('auth_token')) {
      const response = await getUserData(user_id);

      setUserData(response);
    };
  }

  const onGetCategories = async () => {
    const categories = await getCategories();

    setCategories(categories);
  }

  const onSearch = () => {
    if(search) {
      navigate('/catalog?search='+encodeURI(search));
    };
  }

  const onLogout = () => {
    localStorage.clear();
    setUserData(undefined);
  }

  return (
    <div className='header'>
        {windowWidth <= 1000 &&
        <div className={'menu-btn '+(openedMenu ? 'opened': '')}>
          <span></span>
          <span></span>
          <span></span>
        </div>}
        <div className='top content'>
          <div className='top-row row'>
            <Link to=''><img className='logo' src={logo}/></Link>
            {windowWidth > 1000 &&
            <div className='search-input'>
              <input type='text' placeholder='Pesquisar...' onChange={e => updateSearch(e.target.value)} />
              <img src={searchIcon} className='search-icon' onClick={onSearch} />
            </div>}
            {
              windowWidth > 1000 &&
              <>
                {
                  !userData ?
                  <div className='account-options row'>
                    <Link to='sign-up'>
                      <div className='row'>
                        <img src={profileIcon}/>
                        <p>Cadastre-se</p>
                      </div>
                    </Link>
                    <Link to='login'><b>Entre</b></Link>
                  </div> :
                  <div className='account-options row'>
                    <div className='row'>
                      <img src={profileIcon}/>
                      <p>{userData.first_name}</p>
                    </div>
                    <b onClick={onLogout} className='logout'>Logout</b>
                  </div> 
                }
              </>
            }
          </div>  
        </div>
        {windowWidth > 1000 &&
        <div className='bottom'>
          <div className='content'>
            <Content categories={categories} />
          </div>
        </div>}
        {windowWidth <= 1000 &&
        <div className={'menu '+ (openedMenu ? 'opened' : '')}>
          <div className='content'>
            {
              !userData ?
              <div className='account-options row'>
                <Link to='sign-up'>
                  <div className='row'>
                    <img src={profileIcon}/>
                    <p>Cadastre-se</p>
                  </div>
                </Link>
                <Link to='login'><b>Entre</b></Link>
              </div> :
              <div className='account-options row'>
                <div className='row'>
                  <img src={profileIcon}/>
                  <p>{userData.first_name}</p>
                </div>
                <b onClick={onLogout} className='logout'>Logout</b>
              </div> 
            }
            <div className='search-input'>
              <input type='text' placeholder='Pesquisar...' onChange={e => updateSearch(e.target.value)} />
              <img src={searchIcon} className='search-icon' onClick={onSearch} />
            </div>
            <Content categories={categories} />
          </div>
        </div>}
    </div>
  )
}

export default Header

const Content = ({ categories }:{ categories: Category[] }) => {
    return  <ul>
              <li>
                <Link to="">
                  <div className='label-container'>
                    <label>Home</label>
                  </div>
                </Link>
              </li>
              {/* <li className={'menu-item ' + (showCategories ? 'opened' : '')}>
                <div className='label-container row' onClick={() => {toggleCategories(!showCategories); toggleStores(false);}}>
                  <label>Categorias</label>
                  <img className='arrow' src={dropdownIcon}/>
                </div>
                {showCategories && <div className='dropdown-menu'>
                  <ul>
                    <Link to='catalog?type=eletronics'><li onClick={() => {toggleCategories(false); toggleStores(false);}}>Eletrônicos</li></Link>
                  </ul>
                </div>}
              </li> */}
              <li>
                <Link to="catalog?order_by=news">
                  <div className='label-container'>
                    <label>Novidades</label>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="catalog?order_by=offers">
                  <div className='label-container'>
                    <label>Ofertas</label>
                  </div>
                </Link>
              </li>
              {categories.map((category, index) =>
                <li key={'category_'+index}>
                <Link to={"catalog?category_id="+category.category_id}>
                  <div className='label-container'>
                    <label>{category.name}</label>
                  </div>
                </Link>
              </li>)}
              {/* <li className={'menu-item ' + (showStores ? 'opened' : '')}>
                <div className='label-container row' onClick={() => {toggleStores(!showStores); toggleCategories(false);}}>
                  <label>Lojas</label>
                  <img className='arrow' src={dropdownIcon}/>
                </div>
                {showStores && <div className='dropdown-menu'>
                  <ul>
                    <Link to='catalog?store=americanas'><li onClick={() => {toggleCategories(false); toggleStores(false);}}>Americanas</li></Link>
                  </ul>
                </div>}
              </li> */}
            </ul>
}