import { Item } from '../../../infrastructure/services/catalog-item'
import { Link } from 'react-router-dom'
import './column-products.sass'
import { useEffect, useState } from 'react'

function ColumnProduct({ title, catalog_items }: { title: string, catalog_items: Item[] }) {
    const BRReal = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    const[catalog_items_new, set_catalog_items_new] = useState<Item[]>([])

    useEffect(() => {
        catalogItemImages();
    }, [])

    const catalogItemImages = () => {
        const catalog_items_new = catalog_items.map(x => {
            let images = x?.products.map(x => [x.product.image, ...x.product.extra_images]).flat();
            if(images?.length === 0) {
              images = x?.image ? [x?.image] : [''];
            };

            x.image = images[0];
            console.log(x.image);
            return x;
        });

        set_catalog_items_new(catalog_items_new);
    }

    return (
        <div className="column-items">
            <h2>{title}</h2>
            <div className="column-items-column">
                {
                catalog_items_new.map((item, i) =>
                    <Link key={'item_'+i} to={'/catalog/'+item.catalog_item_id}>
                        <div className="item">
                            <div className="product-img">
                                {/* <img className='store-logo' src={item.store?.image} /> */}
                                <img className='img' src={item.image} />
                            </div>
                            <div className="info">
                                <div className='main-info'>
                                    <div className='product-info'>
                                        <h3 className="cut-text">{item.name}</h3>
                                        <p>{item.store?.name}</p>
                                    </div>
                                    <div className='prince-info'>
                                        <div className='price-row row'>
                                            {item.discount_price && <p className='discount'>{BRReal.format(item.discount_price)}</p>}
                                        </div>
                                        {/* {item.discount_percentage &&
                                        <div className='discount-percentage row'>
                                            <b>{item.discount_percentage}%</b>
                                            <span>Desconto</span>
                                        </div>} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>  
                )
                }
            </div>
        </div>
    )
}

export default ColumnProduct