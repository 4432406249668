import { Component } from 'react'
import { ErrorMessage, emptyField, errorsLength, formatErrors, validateEmail } from '../../infrastructure/utils/form-validators'
import { Link } from 'react-router-dom'
import { RouterProps, withRouter } from '../../infrastructure/utils/with-router-component'
import { Input } from '../../components/input'
import { SubmitBtn } from '../../components/submit'
import { encrypt } from '../../infrastructure/utils/encrypt'
import { LoginResponse, onLogin } from '../../infrastructure/services/user'
import { parseJwt } from '../../infrastructure/utils/JWTParser'
import './style.sass'


class Login extends Component<RouterProps> {
    state: any = {
        form: {
            email: '',
            password:  '',
            remember_me: false
        },
        formErrors: {},
        loading: false,
        sended: false
    }

    componentDidMount(): void {
        console.log(this.props);
    }

    onSubmit = async () => {
        const errors = formatErrors({
            email: [validateEmail(this.state.form.email)],
            password: [emptyField(this.state.form.password, 'string')]
        });

        this.setState({ formErrors: errors });

        if(errorsLength(errors) > 0) return;

        this.setState({ loading: true });

        try {

            const form_data = {
                ...this.state.form,
                password: encrypt(this.state.form.password)
              };
              const response = await onLogin(form_data) as LoginResponse;
        
              const result = parseJwt(response.auth_token);
              localStorage.setItem('auth_token', response.auth_token);
              localStorage.setItem('user_id', result.user.user_id);
            
            setTimeout(() => {
                this.setState({ sended: true, loading: false });
                this.props.navigate('/');
            });
        } catch (error) {
            this.setState({ loading: false });
        }
    }


  render() {
    return (
        <div className='page login'>
            <div className='content'>
                <div className='block'>
                    <div className='text'>
                        <h1>Bem vindo!</h1>
                    </div>
                    <div className='form'>
                        <Input
                        name='email'
                        label='Email'
                        placeholder='Insira seu email'
                        onChange={e => this.setState({ form: { ...this.state.form, email: e } })}/>
                        <ErrorMessage errors={this.state.formErrors['email']} />
                        <Input
                        name='password'
                        label='Senha'
                        type='password'
                        placeholder='Insira sua senha'
                        onChange={e => this.setState({ form: { ...this.state.form, password: e } })}/>
                        <ErrorMessage errors={this.state.formErrors['password']} />
                        {/* <div className='extra-questions'>
                            <div className='remember-me' onClick={() => this.setState({ form: { ...this.state.form, remember_me: !this.state.form.remember_me } })}>
                                <input type='checkbox' checked={this.state.form.remember_me} />
                                <p>Lembrar me</p>
                            </div>
                            <Link to='/forgot-password'><p>Esqueceu a senha?</p></Link>
                        </div> */}
                    </div>
                    <SubmitBtn className='secondary' text='Confirmar' loadingText='Enviando...' loading={this.state.loading} onSubmit={this.onSubmit} />
                    <div className='bottom-text'>
                        <p>Ainda não possui uma conta?</p>
                        <Link to='/sign-up'><b>Cadastrar-se</b></Link>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default withRouter(Login);