import { useEffect, useState } from "react"
import gameImg from '../../assets/images/categories/game.png'
import eletronicImg from '../../assets/images/categories/eletronic.png'
import consoleImg from '../../assets/images/categories/console.png'
import bookImg from '../../assets/images/categories/book.png'
import { Link } from "react-router-dom"
import { Item, getCatalog } from "../../infrastructure/services/catalog-item"
function Highlights() {
  const BRReal = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
  const [categories, setCategories] = useState([
    {
      category_id: 1,
      banner: gameImg,
      name: 'Jogos'
    },
    {
      category_id: 1,
      banner: eletronicImg,
      name: 'Eletrônicos'
    },
    {
      category_id: 1,
      banner: consoleImg,
      name: 'Consoles'
    },
    {
      category_id: 1,
      banner: bookImg,
      name: 'Livros'
    }
  ])
  const [hightlights, setHightlights] = useState<Item[]>([])
  const [news, setNews] = useState<Item[]>([])
  const [offers, setOffers] = useState<Item[]>([])
  const [topOffers, setTopOffers] = useState<Item[]>([])

  useEffect(() => {
    setCatalogLists();
  }, [])

  const setCatalogLists = async () => {
    const hightlights = await getCatalog('?order_by=hightlights&limit=10');
    const news = await getCatalog('?order_by=news&limit=10');
    const offers = await getCatalog('?order_by=offers&limit=10');

    setHightlights(hightlights.data);
    setNews(news.data);
    setOffers(offers.data);
    setTopOffers(offers.data.slice(0, 5));    
  }
  

  return (
    <div className="home-content content">
      <div className="categories">
        <h2>Categorias</h2>
        <div className="row">
          {
            categories.map((category, index) =>
              <Link key={'category_'+index} to={'catalog?category='+category.category_id}>
                <div className="category">
                  <h3>{category.name}</h3>
                  <img src={category.banner} />
                  <div className="bg" />
                </div>
              </Link>  
            )
          }
        </div>
      </div>
      <div className="category-items row">
        <div className="hightlights">
          <div className="title row">
            <h2>Destaques</h2>
            <Link to={'catalog?order_by=hightlights'}>Ver mais</Link>
          </div>
          <div className="column">
            {
              hightlights.map((item, index) =>
                <Link key={'hightlight_'+index} to={'catalog/'+item.catalog_item_id}>
                  <div className="item row">
                    <div className="img">
                      <img src={item.image} />
                    </div>
                    <div className="info">
                      <div>
                        <h3 className="cut-text">{item.name}</h3>
                        <p>{item.store?.name}</p>
                      </div>
                      {item.discount_price && <b>{BRReal.format(item.discount_price)}</b>}
                    </div>
                  </div>
                </Link>  
              )
            }
          </div>
        </div>
        <div className="news">
          <div className="title row">
            <h2>Novidades</h2>
            <Link to={'catalog?order_by=news'}>Ver mais</Link>
          </div>
          <div className="column">
            {
              news.map((item, index) =>
                <Link key={'new_'+index} to={'catalog/'+item.catalog_item_id}>
                  <div className="item row">
                    <div className="img">
                      <img src={item.image} />
                    </div>
                    <div className="info">
                      <div>
                        <h3 className="cut-text">{item.name}</h3>
                        <p>{item.store?.name}</p>
                      </div>
                      {item.discount_price && <b>{BRReal.format(item.discount_price)}</b>}
                    </div>
                  </div>
                </Link>  
              )
            }
          </div>
        </div>
        <div className="offers">
          <div className="title row">
            <h2>Ofertas</h2>
            <Link to={'catalog?order_by=offers'}>Ver mais</Link>
          </div>
          <div className="column">
            {
              offers.map((item, index) =>
                <Link key={'offer_'+index} to={'catalog/'+item.catalog_item_id}>
                  <div className="item row">
                    <div className="img">
                      <img src={item.image} />
                    </div>
                    <div className="info">
                      <div>
                        <h3 className="cut-text">{item.name}</h3>
                        <p>{item.store?.name}</p>
                      </div>
                      {item.discount_price && <b>{BRReal.format(item.discount_price)}</b>}
                    </div>
                  </div>
                </Link>  
              )
            }
          </div>
        </div>
      </div>
      <div className="top-offers">
        <h2>Top 5 ofertas</h2>
        <div className="top-offers-slider-container">
          <div className="top-offers-slider row">
            {
              topOffers.map((offer, i) =>
                <Link key={'top_offer_'+i} to={'catalog/'+offer.catalog_item_id}>
                  <div className="top-offer">
                    <span className="ranking-number">{i+1}</span>
                    <div className="img">
                      <img src={offer.image} />
                    </div>
                    <div className="info">
                      <h3>{offer.name}</h3>
                      <p>{offer.store?.name}</p>
                      <b>{offer.discount_price && BRReal.format(offer.discount_price)}</b>
                    </div>
                  </div>
                </Link>  
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Highlights