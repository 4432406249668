import React, { Component } from 'react'
import leftIcon from '../../assets/icons/arrow-left.png'
import rightIcon from '../../assets/icons/arrow-right.png'
import AwesomeSlider from './awesome-slider/AwesomeSlider'

interface State {
  slides: any[],
  currentSlide: any,
  sliderWidth: any,
  sliderInterval: any,
  sliderRef: any
};

export class DoubleSlider extends Component<{ passedSlides: any[] }> {
  state: State = {
    slides: [],
    currentSlide: 1,
    sliderWidth: 0,
    sliderInterval: null,
    sliderRef: null
  }
  sliderRef = React.createRef<any>()

  componentDidMount() {
    if(!this.sliderRef.current) return;

    const sliderWidth = (this.sliderRef.current as HTMLElement).offsetWidth;

    // if(passedSlides.length === 1) {
    //   return;
    // };

    const slidesCopy = this.props.passedSlides.map((x, index) => ({
      image: x
    }));
  
    const firstSlide = [...slidesCopy].shift();
    const lastSlide = [...slidesCopy].pop();

    const slides = [
      lastSlide,
      ...slidesCopy,
      firstSlide
    ].map((x, index) => ({
      ...x,
      left: sliderWidth * index
    }));

    this.setState({
      ...this.state,
      sliderWidth,
      slides
    });

    setTimeout(() => {
      const slidesCopy = slides.map(x => {

        x.left = x.left - sliderWidth;

        return x;
      });

      this.setState({
        ...this.state,
        slidesCopy
      });
    });
  }

  onBtnClick(orientation: string) {
    const slidesCopy = this.state.slides.map(x => {

      x.left = orientation === 'next' ? x.left - this.state.sliderWidth : x.left + this.state.sliderWidth;

      return x;
    });

    this.setState({
      ...this.state,
      slidesCopy,
      currentSlide: orientation === 'next' ? this.state.currentSlide + 1 : this.state.currentSlide - 1
    });
  }

  jumpToPosition(currentSlide: number) {
    this.state.slides.map((x, index) => {

      x.left = (this.state.sliderWidth * index) - (this.state.sliderWidth * currentSlide);

      return x;
    });

    this.setState({
      ...this.state,
      currentSlide
    });
  }

  render() {
    return (
      <div className='double-slider-container'>
        <div ref={this.sliderRef} className='item-slider-container noselect'>
          {this.props.passedSlides.length > 1 &&
          <>
            <button className='previous jump-btn' onClick={() => this.onBtnClick('before')}>
              <img src={leftIcon} />
            </button>
            <button className='next jump-btn' onClick={() => this.onBtnClick('next')}>
              <img src={rightIcon} />
            </button>
          </>}
          <div className='slides'>
            <AwesomeSlider
            slideWidth={'100%'}
            ratio={9/16}
            slideMargin={0}
            passedSlides={this.props.passedSlides}
            currentSlide={this.state.currentSlide}/>
          </div>
        </div>
        {this.props.passedSlides.length > 1 &&
        <div className='bottom-slider'>
          <div className='degradee degradee-left'></div>
          <div className='degradee degradee-right'></div>
          <AwesomeSlider
          slideWidth={'18%'}
          ratio={9/16}
          slideMargin={'4%'}
          // slideWidth={160}
          // slideHeight={90}
          applyFocus={true}
          passedSlides={this.props.passedSlides}
          currentSlide={this.state.currentSlide}/>
        </div>}
      </div>
    )
  }
}