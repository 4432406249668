import { Item } from '../../../infrastructure/services/catalog-item'
import { Link } from 'react-router-dom'
import './slider-products.sass'

function SliderProducts({ title, catalog_items }: { title: string, catalog_items: { catalog_item: Item }[] }) {
    const BRReal = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    return (
        <div className="slider-items">
            <h2>{title}</h2>
            <div className="slider-items-slider-container">
            <div className="slider-items-slider row">
                {
                catalog_items.map(({catalog_item}, i) =>
                    <Link key={'top_offer_'+i} to={'/catalog/'+catalog_item.catalog_item_id}>
                        <div className="item">
                            <div className="img">
                                <img src={catalog_item.image} />
                            </div>
                            <div className="info">
                                <h3>{catalog_item.name}</h3>
                                <p>{catalog_item.store?.name}</p>
                                <b>{catalog_item.discount_price && BRReal.format(catalog_item.discount_price)}</b>
                            </div>
                        </div>
                    </Link>  
                )
                }
            </div>
            </div>
        </div>
    )
}

export default SliderProducts